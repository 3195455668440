<template>
  <resources-table></resources-table>
</template>

<script>

import ResourcesTable from '@/components/user/resources/ResourcesTable'

export default {
  name: 'ResourcesView',
  components: { ResourcesTable }
}
</script>

<style scoped>

</style>
