<template>
  <div>
    <div class="container">
      <div class="row table-wrapper">
        <div class="content-heading d-flex justify-content-between align-items-center">
          <h3 class="text-primary font-22">Resources</h3>
          <div class="d-lg-flex d-md-block d-block mt-md-10">
            <div class="dropdown custom-dropdown mr-50 mb-md-10 mb-20 mb-lg-0">
              <button id="dropdownMenuButton" aria-expanded="false" aria-haspopup="true" class="btn dropdown-toggle"
                      data-toggle="dropdown" type="button">
                {{ searchDict.searchField.header || 'Select column' }}
              </button>
              <div aria-labelledby="dropdownMenuButton" class="dropdown-menu"
                   style="position: absolute; will-change: transform; top: 0; left: 0; transform: translate3d(0px, 40px, 0px);"
                   x-placement="bottom-start">
                <a v-show="searchDict.searchField" class="dropdown-item" @click="selectColumn('')">Select column</a>
                <a v-for="(searchConfig,_index) in tableSearchConfig" :key="_index"
                   class="dropdown-item"
                   @click="selectColumn(searchConfig)">{{ searchConfig.header }}</a>
              </div>
            </div>
            <div class="search-form">
              <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'text'"
                     v-model.trim="searchDict.searchText"
                     class="form-control"
                     placeholder="Search" type="text" @keyup.enter="applySearch()">
              <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date_range'"
                     v-model.trim="searchDict.fromDate"
                     class="form-control mr-2" type="date" @keyup.enter="applySearch()">
              <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date'"
                     v-model.trim="searchDict.date"
                     class="form-control mr-2" type="date" @keyup.enter="applySearch()">
              <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date_range'"
                     v-model.trim="searchDict.toDate"
                     class="form-control"
                     type="date" @keyup.enter="applySearch()">
              <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number'"
                     v-model.trim="searchDict.searchText"
                     class="form-control"
                     placeholder="Search" type="number" @keyup.enter="applySearch()">
              <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number_range'"
                     v-model.trim="searchDict.fromNumber"
                     class="form-control mr-2" placeholder="From" type="number" @keyup.enter="applySearch()">
              <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number_range'"
                     v-model.trim="searchDict.toNumber"
                     class="form-control" placeholder="To" type="number" @keyup.enter="applySearch()">
              <input v-show="!searchDict.searchField" v-model.trim="searchDict.searchText"
                     class="form-control" placeholder="Search" type="text" @keyup.enter="applySearch()">
              <div>
                <button class="btn btn-primary" @click="applySearch()">
                  <img alt="Search" src="@/assets/img/icons/search-glass.svg">
                </button>
                <button class="btn btn-outline-primary ml-2" @click="clearSearch()">
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-striped text-center">
          <thead>
          <tr>
            <th v-for="(item, _i) in tableHeaders" :key="_i"
                @click="applyOrdering(item.db_representation, item.orderColumn)">
              {{ item.title }} <span v-show="orderColumnDbRepresentation === item.db_representation"
                                     :class="[orderBy === '-' ? 'descending': 'ascending']"></span> <br/>
              {{ item.subtitle }}
            </th>
          </tr>
          </thead>
          <tbody v-show="loading">
          <tr>
            <td :colspan="tableHeaders.length">
              <FBTableLoader :columns="5" :height="300" :primaryColor="'#d3d3d3'" :rows="5"
                             class="col-12"></FBTableLoader>
            </td>
          </tr>
          </tbody>
          <tbody v-show="!loading">
          <tr v-for="(rowData, _rowIndex) in tableData" :key="_rowIndex">
            <td v-for="(item, _i) in rowData" :key="_i">
              <a v-if="rowData.length === _i + 1" href="" @click.prevent="getDownloadLink(item)">
                <span v-if="item.icon">
                  <img v-if="!item.icon.relative_url" :src="require(`@/assets/img/icons/story.png`)"
                       alt="Icon"
                       class="resource-icon"
                       @click.prevent="getDownloadLink(item)">
                  <img v-if="item.icon.relative_url" :src="item.icon.relative_url | makeAbsUrl" alt="Icon"
                       class="resource-icon"
                       @click.prevent="getDownloadLink(item)">
                </span>
                <span v-else>-</span>
              </a>
              <span v-else v-html="item"></span>
            </td>
          </tr>
          <tr v-show="tableData.length === 0">
            <td :colspan="tableHeaders.length">No data available.</td>
          </tr>
          </tbody>
        </table>
        <div class="container pl-0 pr-0">
          <div class="">
            <div class="d-lg-flex d-block justify-content-between mt-15 mb-45">
              <div>
                <select v-model="paginateDict.dataPerPage"
                        class="form-control admin-input justify-content-start" @change="getContentsFromServer()">
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </div>
              <div>
                <nav aria-label="Page navigation example">
                  <ul class="pagination pagination-lg justify-content-end">
                    <li :class="{disabled: !paginateDict.firstPage, 'page-item':true}">
                      <a class="page-link" @click="getPaginatedData(paginateDict.firstPage)">First</a>
                    </li>
                    <li v-show="paginateDict.previousPageNumber > 0"
                        :class="{disabled: !paginateDict.previousUrl, 'page-item':true}">
                      <a class="page-link"
                         @click="getPaginatedData(paginateDict.previousUrl)">{{ paginateDict.previousPageNumber }}</a>
                    </li>
                    <li class="page-item disabled"><a class="page-link" href="#">{{
                        paginateDict.currentPageNumber
                      }}</a>
                    </li>
                    <li v-show="paginateDict.nextPageNumber > 0"
                        :class="{disabled: !paginateDict.nextUrl, 'page-item':true}">
                      <a class="page-link" @click="getPaginatedData(paginateDict.nextUrl)">{{
                          paginateDict.nextPageNumber
                        }}</a>
                    </li>
                    <li :class="{disabled: !paginateDict.lastPage, 'page-item':true}">
                      <a class="page-link" @click="getPaginatedData(paginateDict.lastPage)">Last</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UtilityMixin from '@/mixins/utility-mixin'
import ResourceMixin from '@/mixins/common/resource'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { ContentTypes } from '@/config/constants'
import _ from 'lodash'
import { FBTableLoader } from '@/plugins/content-loader/core/components'

export default {
  name: 'ResourcesTable',
  components: { FBTableLoader },
  mixins: [validationMixin, UtilityMixin, ResourceMixin],
  data () {
    return {
      loading: true,
      tableHeaders: [
        {
          title: 'Country',
          subtitle: '',
          db_representation: 'country', // user for apply sort
          orderColumn: 'country.name' // apply ordering js level
        },
        {
          title: 'Type',
          subtitle: '',
          db_representation: 'subtitle',
          orderColumn: 'subtitle'
        },
        {
          title: 'Name',
          subtitle: '',
          db_representation: 'title',
          orderColumn: 'title'
        },
        {
          title: 'Date',
          subtitle: '',
          db_representation: 'date',
          orderColumn: 'date'
        },
        {
          title: 'File/URL',
          subtitle: '',
          db_representation: '',
          orderColumn: ''
        }
      ],
      tableSearchConfig: [
        {
          header: 'Country',
          db_representation: 'country__name',
          widget_type: 'text'
        },
        {
          header: 'Type',
          db_representation: 'subtitle',
          widget_type: 'text'
        },
        {
          header: 'Name',
          db_representation: 'title',
          widget_type: 'text'
        },
        {
          header: 'Date',
          db_representation: 'date',
          widget_type: 'date'
        }
      ],
      tableData: [],
      searchDict: {
        searchField: '',
        searchText: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      },
      paginateDict: {
        nextUrl: null,
        previousUrl: null,
        currentPage: 1,
        totalPage: 1,
        firstPage: null,
        lastPage: null,
        remainingCount: 0,
        nextOffset: 0,
        totalCount: 0,
        dataPerPage: 5,
        previousPageNumber: 0,
        currentPageNumber: 1,
        nextPageNumber: 0
      },
      parentContentType: '',
      apiPrefix: 'v1/resources',
      orderColumnDbRepresentation: 'date',
      orderColumn: 'date'
    }
  },
  validations: {
    searchDict: {
      searchField: {
        required
      }
    }
  },
  methods: {
    selectColumn (searchConfig) {
      this.searchDict = {
        searchField: '',
        searchText: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      }

      this.searchDict.searchField = searchConfig
    },
    clearSearch () {
      this.searchDict = {
        searchField: '',
        searchText: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      }

      this.getContentsFromServer()
    },
    prepareTableContents () {
      try {
        this.tableData = []
        const resources = [] // use for eliminate duplicate row from list
        let _content = this.$store.getters.getUserResourceResources
        const orderBy = this.orderBy === '-' ? 'desc' : 'asc'
        _content = _.orderBy(_content, [this.orderColumn], [orderBy])
        // if (this.orderColumn === 'date') {
        //   _content = _.sortBy(_content, ['date'], [orderBy])
        // }
        for (const _data of _content) {
          // eliminate duplicate row from list
          if (_.filter(resources, { id: _data.id }).length) {
            continue
          } else {
            resources.push({ id: _data.id })
          }
          const tempData = []
          tempData.push(_.get(_data, 'country.name') || 'N/A')
          tempData.push(_data.subtitle)
          tempData.push(_data.title)
          tempData.push(moment(_data.date).format('DD.MM.YYYY'))
          tempData.push(_data)
          this.tableData.push(tempData)
        }
        return this.tableData
      } catch (e) {
        this.errorLog(e)
      }
    },
    generatePagination (data) {
      try {
        this.paginateDict.nextUrl = _.get(data, 'next') || null
        this.paginateDict.previousUrl = _.get(data, 'previous') || null
        this.paginateDict.totalPage = _.get(data, 'total_page') || 0
        this.paginateDict.nextOffset = _.get(data, 'next_offset') || 0
        this.paginateDict.currentPage = _.get(data, 'current_page') || 0
        this.paginateDict.totalCount = _.get(data, 'count') || 0
        this.paginateDict.remainingCount = _.get(data, 'remaining_count') || 0
        this.paginateDict.firstPage = _.get(data, 'first') || null
        this.paginateDict.lastPage = _.get(data, 'last') || null
        // Page number
        this.paginateDict.previousPageNumber = this.paginateDict.previousUrl ? this.paginateDict.currentPage - 1 : 0
        this.paginateDict.currentPageNumber = this.paginateDict.currentPage
        this.paginateDict.nextPageNumber = this.paginateDict.nextUrl ? this.paginateDict.currentPage + 1 : 0
      } catch (e) {
        this.errorLog(e)
      }
    },
    async getContentsFromServer () {
      try {
        this.loading = true
        await this.$store.dispatch('getAllResourceContentsFromServer')
        // start filtering only resources
        const resourceContents = this.$store.getters.getAllResourceContents
        this.parentContentType = resourceContents.find(
          item => item.content_type === ContentTypes.RESOURCE_MINI_CARD)
        // end filtering only resources
        const limit = this.paginateDict.dataPerPage
        const apiUrl = `/api/${this.apiPrefix}/?search=1&offset=0&limit=${limit}&content_type=${ContentTypes.RESOURCE_MINI_CARD}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
        const response = await this.$store.dispatch('getUserResourceContentsFromServer', { apiUrl: apiUrl })
        const data = response.data
        if (data.success) {
          this.generatePagination(data)
          this.$store.commit('setUserResourceContent', data.results)
          await this.prepareTableContents()
        } else {
          this.errorLog(data)
        }
        this.loading = false
      } catch (e) {
        this.errorLog(e)
      }
    },
    async getPaginatedData (apiUrl) {
      this.loading = true
      const response = await this.$store.dispatch('getUserResourceContentsFromServer', { apiUrl: apiUrl })
      const data = response.data
      if (data.success) {
        this.generatePagination(data)
        this.$store.commit('setUserResourceContent', data.results)
        await this.prepareTableContents()
      } else {
        this.errorLog(data)
      }
      this.loading = false
    },
    async applySearch () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please select column.',
          type: 'error',
          duration: 5000
        })
      } else {
        if (this.searchDict.searchField.widget_type === 'text' || this.searchDict.searchField.widget_type === 'number') {
          try {
            this.loading = true
            const offset = 'offset=0'
            const limit = `limit=${this.paginateDict.dataPerPage}`
            const searchParams = `${this.searchDict.searchField.db_representation}=${this.searchDict.searchText}`
            const apiUrl = `/api/${this.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}&content_type=${ContentTypes.RESOURCE_MINI_CARD}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
            const response = await this.$store.dispatch('getUserResourceContentsFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              this.generatePagination(data)
              this.$store.commit('setUserResourceContent', data.results)
              await this.prepareTableContents()
            } else {
              this.errorLog(data)
            }
            this.loading = false
          } catch (e) {
            this.errorLog(e)
          }
        }
        if (this.searchDict.searchField.widget_type === 'date') {
          try {
            this.loading = true
            const _fromDate = Date.parse(this.searchDict.date) - 86400000
            const _toDate = Date.parse(this.searchDict.date) + 86400000
            const offset = 'offset=0'
            const limit = `limit=${this.paginateDict.dataPerPage}`
            const searchKeys = _.split(this.searchDict.searchField.db_representation, ',')
            let searchParams = ''
            if (!_.isEmpty(searchKeys)) {
              const _from = searchKeys[0] || ''
              searchParams = `${_from}=${_fromDate},${_toDate}`
            }
            const apiUrl = `/api/${this.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}&content_type=${ContentTypes.RESOURCE_MINI_CARD}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
            const response = await this.$store.dispatch('getUserResourceContentsFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              this.generatePagination(data)
              this.$store.commit('setUserResourceContent', data.results)
              await this.prepareTableContents()
            } else {
              this.errorLog(data)
            }
            this.loading = false
          } catch (e) {
            this.errorLog(e)
          }
        }
      }
    },
    async applyOrdering (fieldName, orderColumn) {
      if (this.orderBy === '-') {
        this.orderBy = ''
      } else {
        this.orderBy = '-'
      }

      this.orderColumn = orderColumn
      this.orderColumnDbRepresentation = fieldName
      await this.getContentsFromServer()
    }
  },
  async created () {
    await this.getContentsFromServer()
  }
}
</script>
<style scoped>

</style>
